.rdt_Table {
    overflow: auto;
    max-height: 600px; 
  }
  
  .rdt_Table,
  .rdt_TableCol {
    font-weight: bold;
  }
  
  .rdt_Table,
  .rdt_TableCol,
  .rdt_TableCell {
    border-right: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-collapse: collapse;
  }
  
  .rdt_Table .rdt_TableHead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }