/* src/components/CustomDatePicker/CustomDatePicker.css */

/* .react-datepicker-popper {
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(1186.6px, 65.6px) !important;
  } */
  
  .custom-datepicker-input {
    border: none;
    background: transparent;
    font-size: 1.2em;
    color: #000;
    text-align: center;
    cursor: pointer;
  }
  
  .custom-datepicker {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .custom-datepicker .react-datepicker__month-container {
    width: 300px;
    height: auto;
  }
  
  .custom-datepicker .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
  }
  
  .custom-datepicker .react-datepicker__current-month {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .custom-datepicker .react-datepicker__month {
    margin: 10px;
  }
  
  .custom-datepicker .react-datepicker__month-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .custom-datepicker .react-datepicker__month-text {
    font-size: 1.1em;
    width: 30%;
    text-align: center;
    padding: 5px 0;
    margin: 5px 0;
    cursor: pointer;
  }
  
  .custom-datepicker .react-datepicker__month-text--selected,
  .custom-datepicker .react-datepicker__month-text:hover {
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
  }
  
  .custom-datepicker .react-datepicker__navigation {
    top: 10px;
  }
  