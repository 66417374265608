.gradient-card {
    border-radius: 10px;
    /* padding: 10px; */
    margin: 5px 0;
  }
  
  .text-light {
    color: #ffffff;
  }
  
  .bg-flaring-gradient {
    background: linear-gradient(to right, #00c6ff, #0072ff);
  }
  
  .bg-kpi-gradient {
    background: linear-gradient(to right, #ff8a00, #e52e71);
  }
  
  .bg-ytd-gradient {
    background: linear-gradient(to right, #8e2de2, #4a00e0);
  }
  
  .font-weight-bold {
    font-weight: bold;
    font-size: 1.2em;
  }