.top-nav-dashboard-label h6{
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.0em;
    font-weight: normal;
    color: #000;
}

.custom-datepicker-input {
    border: none;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background: transparent;
    font-size: 1.0em !important;
    font-weight: normal;
    color: #000;
    text-align: center;
    cursor: pointer;
}