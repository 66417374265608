.flaring-reasons {
    position: relative;
    /* background-color: #2d2d2d; */
    padding: 20px;
    border-radius: 10px;
  }
  
  .action-container {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .badge-warning {
    cursor: pointer;
    background-color: #ffcc00;
    border-radius: 50%;
    padding: 10px;
  }
  
  .no-data-txt {
    text-align: center;
    color: #fff;
  }
  
  .row.no-gutters {
    margin-top: 20px;
  }
  
  .row.no-gutters .col-md-6 {
    margin-top: 10px;
  }
  
  .row.no-gutters input[type="radio"] {
    margin-right: 5px;
    margin-left: 5px;
  }
  
  .row.no-gutters label {
    color: #fff;
  }
  
  .rotate-label {
    transform: rotate(-90deg);
    transform-origin: center center;
  }
  
.anychart-credits-logo {
  display: none;
}

.anychart-credits-text {
  display: none;
}

  