.pollutant-summary .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .pollutant-series {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .pollutant-tile {
    background-color: #2b2b2b;
    border-radius: 5px;
    padding: 15px;
    /* margin: 10px; */
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
  }
  
  .pollutant-tile.CO2 {
    background: linear-gradient(to right, #00c6ff, #0072ff);
  }
  
  .pollutant-tile.CH4 {
    background: linear-gradient(135deg, #57ebde, #aefb2a);
  }
  
  .pollutant-tile.N2O {
    background: linear-gradient(to right, #ff8a00, #e52e71);
  }
  
  
  .pollutant-tile.CO2e {
    background: linear-gradient(to right, #8e2de2, #4a00e0);
  }
  
  .pollutant-name {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .pollutant-tile h3 {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .yesterday-emission, .monthly-emission, .annual-emission, .violations {
    /* margin-top: 10px; */
  }
  
  .pollutant-tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    z-index: 1000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
  }
  
  .pollutant-tooltip h3 {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 10;
  }
  