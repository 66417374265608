.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}

.nav-link {
  text-decoration: none;
  color: inherit;
}

.nav-link.active span {
  color: #639; /* Active route color */
}
.generate_report_button {
  margin-top: 28px !important;
}

/* // handling table headers freez */

.datepicker-parent {
  position: relative;
  z-index: 99 !important;
}

.mdal-parent {
  position: relative;
  z-index: 9999 !important;
}

.right-align {
  text-align: right;
}

.table-responsive {
  overflow-x: none !important;
  -webkit-overflow-scrolling: touch;
}