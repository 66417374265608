.emission-by-reason {
    margin: 20px;
  }
  
  .emission-by-reason-chart {
    height: 300px; /* Adjust the height as needed */
  }
  
  .alert {
    text-align: center;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    padding: 10px;
    border-radius: 5px;
  }
  
  .alert-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  